@import './../../styles/variables.scss';

#about {
    scroll-margin-top: $SSheaderHeightMobile;

    @include sm {
        scroll-margin-top: $SSheaderHeightDesktop;
    }

    .banner_image {
        background-image: url('./img/store.jpg');
    }

    h3 {
        margin: 5px 0 20px;
        font-size: 1.2rem;

        @include lg {
            font-size: 1.4rem;
        }
    }

    h4 {
        margin: 0 0 10px;
        font-size: 1.2rem;
        color: $SSgreen;

        @include lg {
            font-size: 1.4rem;
        }
    }

    p {
        margin: 0 0 20px;
        font-size: 1rem;

        @include lg {
            max-width: 800px;
            font-size: 1.1rem;
            line-height: 1.6rem;
        }
    }

    .founder_headshot {
        background: url('./img/amy.jpg') no-repeat top center;
        background-size: cover;
        margin: 0 0 10px;
        width: 200px;
        height: 200px;
        border-radius: 50%;
    }

    .contact_link {
        display: block;
        margin: 0 0 10px;

        @include lg {
            font-size: 1.1rem;
            line-height: 1.5rem;
        }
    }

    .map {
        margin: 20px 0 0;
    }
}