@import './../../styles/variables.scss';

#menu {
    scroll-margin-top: $SSheaderHeightMobile;

    @include sm {
        scroll-margin-top: $SSheaderHeightDesktop;
    }


    .banner_image {
        padding: 0;
        background-image: url('./img/menu.jpg');
    }

    h3 {
        margin: 0 0 20px;
        padding: 0 0 5px;
        font-family: CopperPlate, serif;
        font-size: 1.5rem;
        line-height: 1.2rem;
        border-bottom: 1px solid $SSgreen;

        @include lg {
            font-size: 1.8rem;
            line-height: 1.5rem;
        }
    }

    h4 {
        margin: 0;
        font-size: 1.1rem;
        color: $SSgreen;
    }

    ul {
        margin: 0 0 20px;
        padding: 0;

        @include lg {
            margin: 0 0 40px;
        }

        li {
            margin: 0 0 20px;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            justify-content: flex-start;
            align-items: flex-start;

            p {
                margin: 0;
                font-size: 1rem;

                &.price {
                    margin-left: 10px;
                    font-size: 1.1rem;
                    color: $SSorange;
                }

                &.description {
                    margin-top: 5px;
                    font-size: 1rem;
                    flex-basis: 100%;
                }

                &.add_on {
                    margin-top: 5px;
                    flex-basis: 100%;
                    display: flex;
                    justify-content: flex-start;
                    font-size: 1rem;

                    span:first-child {
                        width: 210px;
                    }
                }
            }
        }
    }

    .custom {

        h4 {
            margin-bottom: 10px;
            width: 100%;
        }

        .custom_columns {

            ul {

                @include sm {
                    margin: 0 0 30px;
                }
                @include lg {
                    margin: 0 0 40px;
                }
        
                li {
                    margin: 0 0 10px;
        
                    p {
        
                        &.price {
                            font-size: 1rem;
                        }
                    }
            
                }
            }
    
            @include sm {
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
            }

            > div {

                &:first-child {
                    flex-basis: 100%;

                    @include md {
                        flex-basis: 66%;
                    }
                    @include lg {
                        flex-basis: 50%;
                    }
                }

                @include sm {
                    flex-basis: 50%;
                }
                @include md {
                    flex-basis: 33%;
                }
                @include lg {
                    flex-basis: 25%;
                }
        }
        }
    }
}
