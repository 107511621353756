@import './../../styles/variables.scss';

footer {
    background-color: $SSblack;
    font-size: 12px;
    text-align: center;
    padding: 5px;

    .social_icon {
        display: inline-block;
        padding: 0 5px 5px;
        width: 30px;
        height: auto;
        fill: $SSwhite;
    }

    a {
        color: $SSgreen;
        text-decoration: none;
        
        &:hover, &:active {
            color: $SSgreenHighlight
        }
    }

    .address {
        display: block;
    }

    .pipe {
        color: $SSwhite;
        padding: 0 5px;
    }
}