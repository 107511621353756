@import-normalize; /* bring in normalize.css styles */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');
@import './styles/variables.scss';

@font-face {
  font-family: 'CopperPlate';
  src: url('./fonts/copperplate_gothic_regular.woff') format('woff'),
      url('./fonts/copperplate_gothic_regular.woff2') format('woff2');
}
@font-face {
  font-family: 'CopperPlateBold';
  src: url('./fonts/copperplate_gothic_bold_regular.woff') format('woff'),
      url('./fonts/copperplate_gothic_bold_regular.woff2') format('woff2');
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}

a {
  color: $SSgreen;
  text-decoration: underline;

  &:hover, &:active {
    color: $SSgreenHighlight;
    text-decoration: none;
  }

  &:visited {
    color: $SSgreenHighlight;
    text-decoration: underline;
  }
}

.site_container {
  max-width: $SSmaxWidth;
  margin: 0 auto;
}

.content {
  padding: 10px;

  @include md {
    padding: 20px;
  }
}

.section_header {
  margin: 40px 0 0;
  padding: 10px;
  background-color: $SSblack;
  font-family: CopperPlate, serif;
  font-size: 2.0rem;
  line-height: 1.8rem;
  letter-spacing: -0.05rem;
  font-weight: 400;
  color: $SSgreen;

  @include md {
    padding: 10px 20px;
  }
  @include lg {
    font-size: 2.2rem;
  }
}

.banner_image {
  width: 100%;
  height: 180px;
  margin: 0 0 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}